<template>
  <div>
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">{{ translatedText('loading') }}</h1>
        </div>
      </template>
    </b-loading>
    <section v-if="theater && (theater.estados_id == selectedState || theater.estados_id == 33) && !is_loading" class="theater-page-detail">
      <div class="theater-detail-header">
        <div class="container">
          <div class="columns is-multiline is-vcentered">
            <div v-if="(isMobile || isTablet) && publicidad.length > 0" class="column is-12">
              <Advertising />
            </div>
            <div class="column is-6">
              <h2 class="title">{{ theater.nombre }}</h2>
            </div>
            <div v-if="(!isMobile || !isTablet) && publicidad.length > 0" class="column is-6">
              <Advertising />
            </div>
          </div>
        </div>
      </div>
      <div class="theater-detail-info">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="banner">
                <figure class="image">
                  <img :src="theater.portada ? theater.portada + '/1400/382' : 'https://desarrollo.voyalteatro.mx/backend/api/img/render/9339'" />
                </figure>
              </div>
            </div>
            <div class="column is-12">
              <div class="description">
                <p>{{ theater.descripcion }}</p>
              </div>
            </div>
            <div class="column is-12">
              <div class="description">
                <h4 class="subtitle">{{ translatedText('theaters_direction') }}</h4>
                <p>{{ theater.direccion }}</p>
              </div>
            </div>
            <div v-if="theater.telefono != ''" class="column is-12">
              <div class="description">
                <h4 class="subtitle">{{ translatedText('theaters_phone') }}</h4>
                <p>{{ theater.telefono }}</p>
              </div>
            </div>
            <div v-if="theater.caracteristicas && theater.caracteristicas.length > 0" class="column is-12">
              <div class="features">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <h4 class="subtitle">{{ translatedText('theaters_caracteristitics') }}</h4>
                  </div>
                  <div v-if="theater.espectador == 1" class="column is-3">
                    <article class="media icon-ef">
                      <figure class="media-left">
                        <p class="image is-64x64">
                          <img src="@/assets/images/star.png" />
                        </p>
                      </figure>
                      <div class="media-content">
                        <div class="content">
                          <p>{{ translatedText('loyalty_page_icon_ef') }}</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div v-for="caracteristica in theater.caracteristicas" :key="caracteristica.id" class="column is-3">
                    <article class="media">
                      <figure class="media-left">
                        <p class="image is-64x64">
                          <img class="is-rounded" :src="caracteristica.portada" />
                        </p>
                      </figure>
                      <div class="media-content">
                        <div class="content">
                          <p>{{ caracteristica.nombre }}</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div class="column is-3">
                    <article v-if="theater.aforo != null" class="media icon-aforo">
                      <figure class="media-left">
                        <p class="image is-64x64">
                          <img class="is-rounded" src="@/assets/images/v-teatro-aforo.png" />
                        </p>
                      </figure>
                      <div class="media-content">
                        <div class="content">
                          <p>Aforo: {{ theater.aforo }}</p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="theater.mapa_url" class="column is-12">
              <div class="map">
                <iframe :src="theater.mapa_url" class="w-100"></iframe>
              </div>
            </div>
            <div v-if="theater.rutas_camiones" class="column is-12">
              <div class="maps-actions">
                <b-button class="is-medium vat-btn has-text-white is-3" tag="a" target="_blank" :href="theater.rutas_camiones" type="is-info">
                  {{ translatedText('theaters_view_routes') }}
                </b-button>
                <b-button
                  class="is-medium vat-btn has-text-white is-3"
                  tag="a"
                  target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&query=' + theater.lat + ',' + theater.lon"
                  type="is-info"
                >
                  {{ translatedText('theaters_open_routes') }}
                </b-button>
              </div>
            </div>
            <div v-else-if="theater.lat" class="column is-12">
              <div class="maps-actions">
                <b-button
                  class="is-medium vat-btn has-text-white is-3"
                  tag="a"
                  target="_blank"
                  :href="'https://rutasgdl.com/#!ratio=100&lat=' + theater.lat + '&lon=' + theater.lon + '&z=16'"
                  type="is-info"
                >
                  {{ translatedText('theaters_view_routes') }}
                </b-button>
                <b-button
                  class="is-medium vat-btn has-text-white is-3"
                  tag="a"
                  target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&query=' + theater.lat + ',' + theater.lon"
                  type="is-info"
                >
                  {{ translatedText('theaters_open_routes') }}
                </b-button>
              </div>
            </div>
            <div v-if="theater.iconografias && theater.iconografias.length > 0" class="column is-12">
              <div class="iconography">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <h4 class="subtitle">{{ translatedText('theaters_iconography') }}</h4>
                  </div>
                  <div v-for="iconografia in theater.iconografias" :key="iconografia.id" class="column is-3">
                    <article class="media">
                      <figure class="media-left">
                        <p class="image is-64x64">
                          <img class="is-rounded" :src="iconografia.portada" />
                        </p>
                      </figure>
                      <div class="media-content">
                        <div class="content">
                          <p>{{ iconografia.nombre }}</p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="google-adds">
        <GoogleAdds />
      </section>
    </section>
    <NotFound
      v-if="!is_loading && theater.nombre && theater.estados_id != selectedState && theater.estados_id != 33"
      :title="getNotFoundTitle()"
      :subtitle="getNotFoundSubtitle()"
      :url="'/'"
    />
    <NotFound v-if="!is_loading && !theater.nombre" :title="getNotFoundTitle()" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NotFound from '../404.vue';
import Advertising from '../../../components/Common/advertising.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  name: 'Theater',
  components: {
    NotFound,
    Advertising,
    GoogleAdds,
  },
  props: {
    theaterId: {
      type: Number,
    },
  },
  data() {
    return {
      is_loading: true,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
      isTablet: (state) => state.ui.isTablet,
      theater: (state) => state.theaters.theater,
      selectedState: (state) => state.statesCatalog.selectedState,
      publicidad: (state) => state.dashboard.publicidad,
    }),
  },
  methods: {
    ...mapActions('theaters', ['fetchTheater']),
    async loadTheater() {
      // Obtener el ID del teatro
      const theaterId = this.theaterId || this.$route.params.id;

      if (theaterId) {
        // Llamar a fetchTheater con el ID del teatro
        await this.fetchTheater({ theater_id: theaterId });
      }
      this.is_loading = false;
    },
    getNotFoundTitle() {
      return this.translatedText('theaters_not_found');
    },
    getNotFoundSubtitle() {
      return (
        this.translatedText('theaters_not_found_sub') +
        `"${this.theater.nombre}"` +
        this.translatedText('theaters_not_found_sub_2') +
        `"${this.theater.estado}"` +
        this.translatedText('theaters_not_found_sub_3')
      );
    },
  },
  mounted() {
    this.loadTheater();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadTheater();
    });
  },
};
</script>
